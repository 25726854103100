.footer {
  color: white;
  background-color: var(--color-secondary);
  padding: var(--padding-medium) var(--padding-large);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-small);
}

@media screen and (max-width: 600px) {
  .footer {
    padding: var(--padding-medium);
  }
}

@media screen and (max-width: 400px) {
  .footer {
    flex-direction: column;
  }
}

.switchLocale {
  border: none;
  border-radius: 5px;
  color: white;
  background-color: inherit;
  cursor: pointer;
  transition: opacity 0.3s;
}

@media (hover: hover) {
  .switchLocale:hover {
    opacity: 0.5;
  }
}

.switchLocale:focus {
  outline-offset: 2px;  
  outline: 2px solid var(--color-dark-gray);
}

.tagline {
  margin: 0;
  font-weight: 200;
  font-size: var(--font-size-medium);
  font-style: normal;
  text-align: center;
}

.contacts {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: var(--gap-medium);
  align-items: center; 
}
