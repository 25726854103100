.button {
  font-size: var(--font-size-medium);
  padding: var(--padding-small);
  border: none;
  border-radius: 5px;  
  width: 100%;
  color: white;
  cursor: pointer;
  background-color: var(--color-secondary);
}

.button:focus {
  outline-offset: 2px;
  outline: 2px solid var(--color-dark-gray);
}

.disabled {
  background-color: var(--color-dark-gray);
  cursor: not-allowed;
}
