.container {
  background-color: var(--color-primary);
  padding: var(--padding-large);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-medium);
}

@media screen and (max-width: 300px) {
  .container {
    padding: var(--padding-medium);
  }
}

.spinner {
  width: 200px;
  height: 200px;
  animation: rotation 2s linear infinite;
}

@media screen and (max-width: 420px) {
  .spinner {
    width: 100px;
    height: 100px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text {
  font-size: var(--font-size-small);
  margin: 0;
  position: absolute;
  top: calc(40px + 100px - var(--font-size-small) / 2);
}

@media screen and (max-width: 420px) {
  .text {
    color: transparent;
  }
}
