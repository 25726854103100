.contact {
  display: block;
  transition: opacity 0.3s;
}

@media (hover: hover) {
  .contact:hover {
    opacity: 0.5;
  }
}

.link {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--icon-size-medium);  
}

.link:focus {
  outline-offset: 2px;  
  outline: 2px solid var(--color-dark-gray);
}

.icon {
  width: var(--icon-size-medium);
  height: var(--icon-size-medium);
}
