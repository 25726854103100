:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
  
  --color-primary: #F5F5F5;
  --color-secondary: #282C34;
  --color-light-gray: #CCCCCC;
  --color-dark-gray: #888888;
  
  --font-size-large: 40px;
  --font-size-medium: 20px;
  --font-size-small: 16px;
  
  --padding-large: 40px;
  --padding-medium: 20px;
  --padding-small: 10px;
  
  --gap-large: 40px;
  --gap-medium: 20px;
  --gap-small: 10px;  
  
  --icon-size-medium: 24px;
}

@media screen and (max-width: 900px) {
  :root {
    --font-size-large: 36px;
    --font-size-medium: 18px;
    
    --icon-size-medium: 22px;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --font-size-large: 32px;
    --font-size-medium: 16px;
    
    --icon-size-medium: 20px;
  }
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

.body {
  color: black;
}
