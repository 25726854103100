.table {
  width: 70%;
  color: var(--color-dark-gray);
  font-size: var(--font-size-medium);
  border-collapse: collapse;
}

@media screen and (max-width: 900px) {
  .table {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .table {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .table {
    width: 100%;
  }
}
