.app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.main {
  flex-grow: 1;  
  padding: var(--padding-medium);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-large);  
  
  background-color: var(--color-primary);
}
