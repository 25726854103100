.input {
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-medium);
  padding: var(--padding-small);
  border: 2px solid var(--color-light-gray);
  border-radius: 5px;
}

.input:focus {
  outline-offset: 2px;
  outline: 2px solid var(--color-dark-gray);
}

.invalid {
  border-color: red;
}
