.header {
  color: white;
  background-color: var(--color-secondary);
  padding: var(--padding-medium);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 352px) {
  .header {
    flex-direction: column;
  }
}

.name {
  margin: 0;
  font-weight: 900;
  font-size: var(--font-size-large);
  font-style: normal;
}

.tagline {
  margin: 0;
  font-weight: 200;
  font-size: var(--font-size-large);
  font-style: normal;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .tagline {
    font-size: var(--font-size-medium);
  }
}
